<template>
	<div>
		<TablesDefault
			v-model:query="computedQuery"
			v-model:currentFilterValues="currentFilterValues"
			:use-table-element="false"
			:container-class="containerClass"
			:records="records"
			:count="store.activeCount[instanceLabel]"
			:filters="tabs"
			:use-search="useSearch"
			:use-pagination="usePagination"
			:per-page="store.instances[instanceLabel]?.perPage"
			:current-page="store.instances[instanceLabel]?.currentPage"
			:current-sort="store.instances[instanceLabel]?.sort"
			:has-next-page="store.hasNextPage(instanceLabel)"
			:is-loading="store.showLoading(instanceLabel)"
			:has-completed-first-search="store.instances[instanceLabel]?.hasCompletedFirstSearch"
			@previous="store.previousPage(instanceLabel)"
			@next="store.nextPage(instanceLabel)"
			@sort="store.setSort(instanceLabel, {[$event[0]]: $event[1]})"
		>
			<template #default>
				<MovesRow v-for="(record, index) in records" :key="record.id" :record="record" :layout="layout" :class="store.instances[instanceLabel].queryData.length % 2 ? (index === store.instances[instanceLabel].queryData.length - 1 ? 'col-span-2' : '') : ''" />
			</template>
			<template v-if="instanceLabel === 'pending'" #empty-default>
				<div class="w-full my-56 mx-auto block">
					<NotAllowed title="You're all caught up!">
						<template #illustration>
							<IconsRelaxedBird class="w-56 stroke-1 inline-block" />
						</template>
					</NotAllowed>
				</div>
			</template>
		</TablesDefault>
	</div>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, PropType, ref, watch} from "vue";
import {useSession} from "~/stores/session";
import {MoveFacets, useMoves} from "~/stores/moves";
import {format} from "date-fns";
import {MoveStatus, MoveStatuses} from "~/utils/MoveStatuses";
import type {MoveDirection, MoveEventType, MoveRecord, TableFilters} from "~/utils/types";

const session = useSession();
const store = useMoves();
const props = defineProps({
	query: {
		type: String,
		required: false,
		default: ""
	},
	layout: {
		type: String as PropType<"default" | "dashboard">,
		required: false,
		default: "default"
	},
	instanceLabel: {
		type: String,
		required: true
	},
	useSearch: {
		type: Boolean,
		required: false,
		default: true
	},
	usePagination: {
		type: Boolean,
		required: false,
		default: true
	}
});

const emit = defineEmits(['update:query']);

const containerClass = computed(() => {
	return 'divide-y divide-jmi-purple4 overflow-hidden rounded-lg bg-white sm:bg-jmi-purple4 shadow sm:grid sm:gap-px sm:divide-y-0 ' + (props.layout === 'default' ? 'sm:grid-cols-2' : '');
});

const records = computed<MoveRecord[]>(() => {
	return store.instances[props.instanceLabel]?.queryData ?? [];
});

const computedQuery = computed({
	get() {
		return props.query;
	},
	set(value) {
		emit('update:query', value);
	}
});

onBeforeMount(() => {
	store.search(props.instanceLabel, props.query);
});

const intl = new Intl.NumberFormat('en-GB');
const refreshCounts = (): TableFilters<MoveFacets> => {
	if (!props.useSearch) {
		return {};
	}

	let statuses = MoveStatuses;

	statuses = statuses.map((status) => {
		status.label = status.id + ` (${intl.format(store.count(props.instanceLabel, 'status', status.id))})`;
		return status;
	});

	let movedate_placeholder = undefined;
	if (store.instances["latest-move-date"]?.queryData && store.instances["latest-move-date"]?.queryData.length > 0) {
		let latestMoveDate = store.instances["latest-move-date"]?.queryData[0]?.movein?.movedate;
		movedate_placeholder = [
			format(session.start_date, 'dd/MM/yyyy'),
			format(latestMoveDate ? new Date(latestMoveDate * 1000) : new Date(), 'dd/MM/yyyy'),
		].join("  -  "); // use date-fns to format
	}

	let created_at_placeholder = [
		format(session.start_date, 'dd/MM/yyyy'),
		format(new Date(), 'dd/MM/yyyy'),
	].join("  -  ");

	return {
		types: {
			title: "Type",
			options: [
				{label: `Sales (${intl.format(store.count(props.instanceLabel, 'types', 'sale'))})`, id: 'sale'},
				{label: `Lets (${intl.format(store.count(props.instanceLabel, 'types', 'letting'))})`, id: 'letting'},
			]
		},
		managed: {
			title: "Managed",
			options: [
				{label: `Managed (${intl.format(store.count(props.instanceLabel, 'managed', 'true'))})`, id: true},
				{label: `Non-managed (${intl.format(store.count(props.instanceLabel, 'managed', 'false'))})`, id: false},
			]
		},
		directions: {
			title: "Move direction",
			options: [
				{label: `In (${intl.format(store.count(props.instanceLabel, 'directions', 'in'))})`, id: 'in'},
				{label: `Out (${intl.format(store.count(props.instanceLabel, 'directions', 'out'))})`, id: 'out'},
			]
		},
		status: {
			title: "Status",
			options: statuses
		},
		created_at: {
			title: "Date added",
			type: 'date-range',
			placeholder: created_at_placeholder,
		},
		movedate: {
			title: "Move date",
			type: 'date-range',
			placeholder: movedate_placeholder,
		},
	};
};
const tabs = ref(refreshCounts());

const currentFilterValues = computed({
	get() {
		let data: Partial<MoveFacets> = {
			types: undefined as MoveEventType[] | undefined,
			managed: undefined as boolean[] | undefined,
			directions: undefined as MoveDirection[] | undefined,
			status: undefined as MoveStatus[] | undefined,
			created_at: undefined as [string, string] | undefined,
			movedate: undefined as [string, string] | undefined,
		};

		// The code below gets rid of Proxy objects that will mess with changes to the object values.

		if (store.instances[props.instanceLabel]?.queryFacets.types) {
			data.types = [...store.instances[props.instanceLabel].queryFacets.types!];
		}

		if (store.instances[props.instanceLabel]?.queryFacets.managed) {
			data.managed = [...store.instances[props.instanceLabel].queryFacets.managed!];
		}

		if (store.instances[props.instanceLabel]?.queryFacets.directions) {
			data.directions = [...store.instances[props.instanceLabel].queryFacets.directions!];
		}

		if (store.instances[props.instanceLabel]?.queryFacets.status) {
			data.status = [...store.instances[props.instanceLabel].queryFacets.status!];
		}

		if (store.instances[props.instanceLabel]?.queryFacets.created_at) {
			data.created_at = store.instances[props.instanceLabel].queryFacets.created_at!;
		}

		if (store.instances[props.instanceLabel]?.queryFacets.movedate) {
			data.movedate = store.instances[props.instanceLabel].queryFacets.movedate!;
		}

		return data;
	},
	set(value) {
		for (const [key, subValue] of Object.entries(value)) {
			store.setFacet(props.instanceLabel, key as keyof MoveFacets, subValue, false);
		}
		store.searchAndResetPage(props.instanceLabel, store.instances[props.instanceLabel].query);
	}
});

const headers = [
	{
		label: "Mover",
		id: "customer.full_name_and_title"
	},
	{
		label: "Move Out",
		id: "moveout.address"
	},
	{
		label: "Move Out Date",
		id: "moveout.movedate"
	},
	{
		label: "Move In",
		id: "movein.address"
	},
	{
		label: "Move In Date",
		id: "movein.movedate"
	},
	{
		label: "Status",
		id: "status"
	},
	{
		label: "Added",
		id: "created_at"
	},
];

store.boot('latest-move-date', {
	sort: {"movein.movedate":"desc"},
	perPage: 1,
});

watch(() => props.query, () => {
	store.searchAndResetPage(props.instanceLabel, props.query)
});

watch(() => store.instances[props.instanceLabel]?.countFetchedAt, () => {
	tabs.value = refreshCounts();
});

watch(() => store.instances["latest-move-date"]?.countFetchedAt, () => {
	tabs.value = refreshCounts();
});

</script>
