<template>
	<NuxtLink :key="'move-' + record.id" :to="url" :dusk="'move-' + record.id" class="relative group bg-white lg:px-6 lg:pt-5 lg:pb-11 md:px-6 md:pt-5 md:pb-11 focus-within:ring-2 focus-within:ring-inset focus-within:ring-jmi-purple3 hover:bg-jmi-purple5 transition">
		<div class="p-6 lg:p-0">
			<div class="flex justify-between">
				<div class="text-lg font-medium font-obviously-regular tracking-tighter" v-html="formattedCustomerName" />
				<span class="pointer-events-none" aria-hidden="true">
					<ArrowUpRightIcon class="h-5 w-5 stroke-1 text-jmi-purple3 group-hover:text-jmi-purple2" />
				</span>
			</div>
			<div class="mt-6">
				<div class="flex flex-col">
					<div class="flex space-x-3 mb-6 items-start items-center">
						<div class="flex-shrink-0">
							<div class="rounded-full overflow-hidden object-contain bg-jmi-coral5 p-1.5">
								<img class="h-11 w-11" src="/images/keys.svg" alt="Move In">
							</div>
						</div>
						<div class="min-w-0 flex-1">
							<span class="absolute inset-0" aria-hidden="true" />
							<div v-if="movein">
								<p class="text-sm font-medium text-jmi-purple1">
									Move In
								</p>
								<p class="text-sm text-jmi-purple2" v-html="movein.address" />
								<p class="text-xs italic mt-0.5 text-jmi-purple2">
									<span v-if="movein.type === 'letting'">
										Tenancy start date: {{ movein.movedate }}
									</span>
									<span v-else-if="movein.type === 'sale'">
										Completion date: {{ movein.movedate }}
									</span>
									<span v-else>
										No move in type
									</span>
								</p>
								<MovesLabelInfo v-if="movein?.type" :move-event="movein">
									<LazyDynamicLabel v-if="record.status === 'On Hold'" label-class="text-white bg-jmi-coral2" :text="'on hold'" />
								</MovesLabelInfo>
								<!-- tags -->
							</div>
							<div v-else>
								<p class="text-sm text-jmi-purple3 italic">
									No Move In
								</p>
							</div>
						</div>
					</div>
					<div class="flex space-x-3 mb-6 items-start items-center">
						<div class="rounded-full overflow-hidden object-contain bg-jmi-coral5 p-1.5">
							<img class="h-11 w-11" src="/images/boxes.svg" alt="Move Out">
						</div>
						<div class="min-w-0 flex-1">
							<span class="absolute inset-0" aria-hidden="true" />
							<div v-if="moveout">
								<p class="text-sm font-medium text-jmi-purple1">
									Move Out
								</p>
								<p class="text-sm text-jmi-purple2" v-html="moveout.address" />
								<p class="text-xs italic mt-0.5 text-jmi-purple2">
									<span v-if="moveout.type === 'letting'">
										Tenancy end date: {{ moveout.movedate }}
									</span>
									<span v-else-if="moveout.type === 'sale'">
										Completion date: {{ moveout.movedate }}
									</span>
									<span v-else>
										No move out type
									</span>
								</p>
								<MovesLabelInfo v-if="moveout?.type" :move-event="moveout">
									<LazyDynamicLabel v-if="record.status === 'On Hold'" label-class="text-white bg-jmi-coral2" :text="'on hold'" />
								</MovesLabelInfo>
							</div>
							<div v-else>
								<p class="text-sm text-jmi-purple3 italic">
									No Move Out
								</p>
							</div>
						</div>
					</div>
					<span class="mt-2 text-xs text-jmi-purple2 lg:absolute lg:bottom-6 md:absolute md:bottom-6 left-6 pr-14">
						<span class="font-medium">Status:</span> <span class="mr-1" :class="record.status === 'On Hold' ? 'text-jmi-coral1' : ''">{{ record.status }}</span>
						<span class="font-medium">Added:</span> <span :dusk="'move_created_at'">{{ created_at }}</span>
					</span>
				</div>
			</div>
		</div>
		<hr class="w-full block sm:hidden">
	</NuxtLink>
</template>
<script setup lang="ts">
import {
	ArrowUpRightIcon,
} from '@heroicons/vue/24/outline';
import {computed, ComputedRef, PropType} from "vue";
import {useMoves} from "~/stores/moves";
import {MoveEvent, MoveRecord} from "~/utils/types";
import {addBreadcrumb, captureMessage} from "@sentry/vue";
const intl = new Intl.DateTimeFormat('en-GB');
const store = useMoves();
const props = defineProps({
	record: {
		type: Object as PropType<MoveRecord>,
		required: true
	},
	layout: {
		type: String as PropType<"default" | "dashboard">,
		required: false,
		default: "default"
	},
});
const url = computed(() => {
	return props.record.is_pending ? '/moves/edit/' + props.record.id : '/moves/view/' + props.record.id;
});
const formattedCustomerName = computed(() => {
	if (typeof props.record._formatted !== 'undefined') {
		return props.record._formatted.customer.full_name_and_title;
	} else {
		if (typeof props.record.customer !== 'undefined') {
			return props.record.customer.full_name_and_title;
		} else {
			// We're explicitly not indexing out moves with no customer in meilisearch, so this should never happen.
			// But if it does, we want to know about it, so we'll log it and send a message to Sentry.

			addBreadcrumb({
				category: "warning",
				message: "Move record has no customer or _formatted.customer.",
				level: "warning",
				data: {
					"record": props.record,
				},
			});

			captureMessage("Move record has no customer or _formatted.customer.", {
				level: "warning",
				tags: {
					"move_id": props.record.id
				},
			});

			return "Unknown";
		}
	}
});
const created_at = computed(() => {
	let date = new Date(props.record.created_at * 1000);
	return intl.format(date);
});
const movein: ComputedRef<MoveEvent | undefined> = computed(() => {
	return store.getMoveinEvent(props.record);
});
const moveout: ComputedRef<MoveEvent | undefined> = computed(() => {
	return store.getMoveoutEvent(props.record);
});
</script>
