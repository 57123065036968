export type MoveStatus = `Sales Pre-Move Call Pending` | `Requires Appointment` | `Concierge Call Pending` | `Callback Booked` | `Managing` | `Complete` | `Rejected` | `Not Contactable` | `Move Cancelled By Customer` | `Move Cancelled By Agency` | `Move Cancelled By Unknown` | `Complete (No Consent)` | `On Hold` | `Pre-Appointment` | `In Progress`;

const MoveStatuses: { label: MoveStatus, id: MoveStatus }[] = [
	{label: `Sales Pre-Move Call Pending`, id: 'Sales Pre-Move Call Pending'},
	{label: `Requires Appointment`, id: 'Requires Appointment'},
	{label: `Concierge Call Pending`, id: 'Concierge Call Pending'},
	{label: `Callback Booked`, id: 'Callback Booked'},
	{label: `Managing`, id: 'Managing'},
	{label: `Complete`, id: 'Complete'},
	{label: `Rejected`, id: 'Rejected'},
	{label: `Not Contactable`, id: 'Not Contactable'},
	{label: `Move Cancelled By Customer`, id: 'Move Cancelled By Customer'},
	{label: `Move Cancelled By Agency`, id: 'Move Cancelled By Agency'},
	{label: `Move Cancelled By Unknown`, id: 'Move Cancelled By Unknown'},
	{label: `Complete (No Consent)`, id: 'Complete (No Consent)'},
	{label: `On Hold`, id: 'On Hold'},
	{label: `Pre-Appointment`, id: 'Pre-Appointment'},
	{label: `In Progress`, id: 'In Progress'},
];

export {MoveStatuses};
